import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import GetBannerDto from '../models/common/GetBannerDto';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./BannerListItem.css";
import { useNavigate } from 'react-router-dom';
import { useEndpoints } from '../utils/EndpointContext';

interface BannerListItemProps {
    item: GetBannerDto;
    fetchItems: () => void;
}

const BannerListItem: React.FC<BannerListItemProps> = ({ item: banner, fetchItems }) => {

    const navigate = useNavigate();
    const endPoints = useEndpoints();
    const deleteBanner = endPoints.bannerManagerDelete;

    const handleBannerClick = (rowKey: string) => {
        navigate(`/Banners/${rowKey}`);
    };

    const handleDelete = async (event: React.MouseEvent<SVGSVGElement, MouseEvent>, rowKey: string) => {
        event.stopPropagation(); 
            try {
                const response = await fetch(deleteBanner as RequestInfo, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                    body: rowKey
                });
            if (response.status === 200) {
                fetchItems();
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <Grid container className="banner-list-box" key={banner.RowKey} onClick={() => handleBannerClick(banner.RowKey)}>
            
                <Grid item className="banner-list-item" xs={6} sm={4} md={4} lg={2} xl={2} style={{ flexGrow: 1 }}>
                    <Typography className="banner-list-text">{banner.BannerName}</Typography> 
                </Grid>
            <Grid item className="banner-list-item" xs={6} sm={4} md={4} lg={2} xl={2} style={{ flexGrow: 1 }}>
                <Typography className="banner-list-text">{banner.BannerPlacement}</Typography> 
            </Grid>
            <Grid item className="banner-list-item"xs={6} sm={4} md={4} lg={2} xl={2} style={{ flexGrow: 1 }}>
                <Typography className="banner-list-text">{banner.BannerAlignment}</Typography> 
            </Grid>
            <Grid item className="banner-list-item banner-list-text" xs={6} sm={4} md={4} lg={2} xl={2} style={{ flexGrow: 1 }}>
                <Link href={banner.BannerLink} target="_blank" rel="noopener noreferrer">
                    Banner Link
                </Link>
            </Grid>
            <Grid item className="banner-list-item" xs={12} sm={6} md={6} lg={3} xl={3} style={{ flexGrow: 1 }}>
            <img 
                src={banner.BannerBlobUri} 
                alt={banner.BannerName}
                style={{ maxWidth: '150px', maxHeight: '100px', width: 'auto', height: 'auto' }}
            />   
            </Grid>   
            <Grid item className="banner-list-item banner-list-delete-icon" xs={12} sm={2} md={2} lg={1} xl={1} style={{ flexGrow: 1 }}>
                <FontAwesomeIcon icon={faTrashCan} onClick={(event) => handleDelete(event, banner.RowKey)} />
            </Grid>   
        </Grid>  
    );
};

export default BannerListItem;
