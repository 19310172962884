import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import AppRoutes from "../../AppRoutes";
import { AppRoute } from '../../models/common/AppRoute';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../logo.svg';
import { Typography } from '@mui/material';
import { useState } from 'react';

type Props = {
    drawerWidth: number
}

const Nav = (props : Props) => {
    const drawerWidth = props.drawerWidth;
    const location = useLocation();

    const [collapsed, setCollapsed] = useState(false);

    const activeRoute = (routeName: string) => {
        let result = location.pathname.indexOf(routeName) > -1 ? true : false;
        return result;
    };

    const createLinks = (routes : AppRoute[]) => {
        return routes.map((route) => {
            if(route.isVisible){
                return (<MenuItem key={route.name} component={<Link to={route.path} />} active={activeRoute(route.path)}>{route.name} </MenuItem>);
            }
            return (<></>);
        });
    };

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    marginTop: '78px',
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Link to={'/'} className="App-logo" ><img src={logo} alt="logo"  /></Link>
            <Divider />
            <Sidebar width={drawerWidth.toString()}>
                <div style={{ flex: 1, marginTop: '1em' }}>
                    <div style={{ padding: '0 24px', marginBottom: '8px' }}>
                        <Typography
                            variant="body2"
                            fontWeight={600}
                            style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
                        >
                            Banner manager Menu
                        </Typography>
                    </div>
                    <Menu>
                        {createLinks(AppRoutes)}
                    </Menu>
                </div>
            </Sidebar>
        </Drawer>
    );
}

export default Nav;