import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { Endpoints } from "./data/Endpoints";


export const createMsalInstance = (endPoints: Endpoints) => {
  const msalConfig = {
    auth: {
      clientId: endPoints.b2cPolicies.clientId,
      authority: endPoints.b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [endPoints.b2cPolicies.authorityDomain],
      redirectUri: endPoints.b2cPolicies.redirectUri,
      navigateToLoginRequestUrl: false, 
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
    },
    system: {
      loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        }
      }
    }
  };
  return new PublicClientApplication(msalConfig);
}
  export const loginRequest = {
    scopes: ["openid", "profile"],
  };

  export const tokenRequest = {
    scopes: [],
    forceRefresh: false 
  };